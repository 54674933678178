import React from "react"
import { Link } from "gatsby"
import moment from "moment-strftime"

import AssetImage from "../AssetImage"
import TagsList from "./TagsList"

const PostCard = ({ post, className }) => {
  const title = post.frontmatter.title || post.fields.slug
  const description = post.frontmatter.excerpt || post.excerpt
  const tagsGroup = { group: post.frontmatter.tags.map(tag => { return { fieldValue: tag, totalCount: 0 } }) }

  return (
    <div className={`${className || ""} border-none`}>
      <div className="rounded overflow-hidden shadow-lg md:mx-2 mb-4">
        <article
          className="post-list-item"
          itemScope
          itemType="http://schema.org/Article"
        >
          {post.frontmatter.imgPath && (
            <Link
              to={post.fields.slug}
              itemProp="url"
            >
              <div className="">
                <AssetImage
                  imgClassName="w-full h-32 sm:h-48"
                  imgPath={post.frontmatter.imgPath}
                  imgAlt={post.frontmatter.imgAlt}
                />
              </div>
            </Link>
          )}
          <div className="px-6 py-6">
            <Link
              to={post.fields.slug}
              itemProp="url"
            >
              <header>
                <h2 className="mb-0" itemProp="headline">
                  {title}
                </h2>
                <small className="text-meta uppercase">
                  <time className="published" dateTime={moment(post.frontmatter.date).strftime('%Y-%m-%d %H:%M')}>
                    {moment(post.frontmatter.date).strftime('%e %B %Y')}
                  </time>
                  {"\u00A0\u00A0\u00A0•\u00A0\u00A0\u00A0"}
                  {post.timeToRead} min read
                    </small>
              </header>
              <section className="mt-2">
                <p className="text-base mb-0" itemProp="description">
                  {description}
                </p>
              </section>
            </Link>
            <TagsList tagsGroup={tagsGroup} />
          </div>
        </article>
      </div>
    </div>
  )
}

export default PostCard
